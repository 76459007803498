import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { Observable, catchError, delay, filter, map, of, take } from 'rxjs';
import { MenuService } from 'src/app/pages/components/service/app.menu.service';
import { AdminMenuItem } from 'src/app/shared/interfaces/menu.interface';
import { searchIfSomeValueIsExistInTree } from 'src/app/shared/utils/tree.utils';

@Injectable({
  providedIn: 'root',
})
export class MenuNavigationGuard  {
  private isNavigatedFromAnotherRoute: boolean = false;
  constructor(
    private _MenuService: MenuService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event: any) => {
        if (event.navigationTrigger === 'imperative') {
          this.isNavigatedFromAnotherRoute = true;
        }
      });
  }
  get menuTree() {
    return this._MenuService.getDataFromStoreValue(['menuItems', 'data'])?.[0]
      ?.items;
  }
  get menuTree$() {
    return this._MenuService.selector$(['menuItems', 'data']);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handlePermissionCheck(route, state);
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handlePermissionCheck(route, state);
  }

  handlePermissionCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (state.url == './' || state.url == '/pages/' || state.url == '/pages')
      return true;
    this.spinner.show('routing-spinner');

    if (this.menuTree?.length) {
      this.spinner.hide('routing-spinner');
      // return searchIfSomeValueIsExistInTree(this.menuTree, 'routerLink', [url, urlWithoutPage]);
      const isAuthorized = isAuthorizedRoute(this.menuTree, state.url);
      if (!isAuthorized && !this.isNavigatedFromAnotherRoute) {
        const firstValidRoute = findElementWithRouterLink(
          this.menuTree
        )?.routerLink?.replace('./', '/pages/');
        if (firstValidRoute) this.router.navigate([firstValidRoute]);
        return false;
      } else {
        return isAuthorized;
      }
    } else
      return this._MenuService.getMenu().pipe(
        map((menu) => {
          this.spinner.hide('routing-spinner');
          const isAuthorized = isAuthorizedRoute(menu.data, state.url);
          if (!isAuthorized && !this.isNavigatedFromAnotherRoute) {
            const firstValidRoute = findElementWithRouterLink(
              menu.data
            )?.routerLink?.replace('./', '/pages/');
            if (firstValidRoute) this.router.navigate([firstValidRoute]);
            return false;
          } else {
            return isAuthorized;
          }
        }),
        catchError(() => {
          return of(false);
        })
      );
  }
}

function matchRoute(pattern: string, route: string): boolean {
  if (!pattern) return false;
  if (pattern.includes('/pages')) pattern = pattern?.replace('/pages', '.');
  if (route.includes('/pages')) route = route?.replace('/pages', '.');

  const patternParts = pattern.split('/');
  const routeParts = route.split('/');

  if (patternParts.length !== routeParts.length) {
    return false;
  }

  for (let i = 0; i < patternParts.length; i++) {
    if (patternParts[i] !== routeParts[i] && !patternParts[i].startsWith(':')) {
      return false;
    }
  }

  return true;
}

export function isAuthorizedRoute(
  authorizedMenuRoutes: AdminMenuItem[],
  currentRoute: string
): boolean {
  if (currentRoute.includes('/pages'))
    currentRoute = currentRoute.replace('/pages', '.');

  for (const node of authorizedMenuRoutes) {
    if (matchRoute(node?.routerLink, currentRoute)) {
      // The current node's route matches the current route
      return true;
    } else if (node?.items && node?.items?.length) {
      // If the node has child nodes, recursively check them
      const isChildAuthorized = isAuthorizedRoute(node.items, currentRoute);

      if (isChildAuthorized) {
        // If any child node is authorized, return true
        return true;
      }
    }
  }
  // No match found in the tree
  return false;
}

export function isAuthorizedRouteByKey(
  authorizedMenuRoutes: AdminMenuItem[],
  routeKey: string
): boolean {
  for (const node of authorizedMenuRoutes) {
    if (routeKey.toLocaleLowerCase() === node.key.toLocaleLowerCase()) {
      // The current node's route matches the current route
      return true;
    } else if (node?.items && node?.items?.length) {
      // If the node has child nodes, recursively check them
      const isChildAuthorized = isAuthorizedRoute(node.items, routeKey);

      if (isChildAuthorized) {
        // If any child node is authorized, return true
        return true;
      }
    }
  }
  // No match found in the tree
  return false;
}

export function findElementWithRouterLink(tree: MenuItem[], excludedRoutes?:string[]): MenuItem | null {
  const hasExcludedRoutes: boolean = Boolean(excludedRoutes?.length);
  try {
    for (const node of tree) {
      if (
        node.routerLink &&
        (!node.items || node.items.length === 0) &&
        node.visible
      ) {
        return node;
      } else if (node.items && node.items.length > 0) {
        const found = findElementWithRouterLink(node.items);
        if (found) {
          return found;
        }
      }
    }
  } catch (error) {
    return null;
  }
  return null;
}
