import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environment/environment';
import { AuthServiceService } from '../authentication/Services/auth-service.service';
import { MenuService } from '../pages/components/service/app.menu.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorsService implements HttpInterceptor {
  readonly initAPI = environment.signInEndPoints
  constructor(
    private auth: AuthServiceService,
    private menuService: MenuService,
    private messageService: MessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string | null = localStorage.getItem('token');

    const isInitAPI = this.initAPI.some((endPoint) =>
      request.url.toLowerCase().includes(endPoint?.toString().toLowerCase())
    );

    request = request.clone({
      headers: request.headers.set('Authorization', environment.basicAuth),
    });
    if (token && !isInitAPI) {
      request = request.clone({
        headers: request.headers.set('token', token),
      });
    }

    request = request.clone({
      headers: request.headers
        .set('apikey', 'a712d3ba-7ce8-11eb-8e6f-0')
        .set('appcode', 'BNPL01')
        .set('Accept', 'application/json')
        .set('X-Frame-Options', 'SAMEORIGIN'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        //   //console.log('event--->>>', event);
        // }
        return event;
      }),
      catchError(error => {
        if (error.status === 401) {
          console.error("Status Code 401 Error: ", error);
          this.messageService.add({
            severity: 'error', 
            summary: 'Error', 
            detail: 'Your session has expired. Please login again.',
          });
          this.auth.cleanUpCurrentUserSession();
        } else if (error.status === 200){
          console.error("Status Code 403 Forbidden Error: ", error);
          // interceptor-message
          this.messageService.add({ 
            severity: 'error', 
            summary: 'Error', 
            detail: 'You are not authorized to access this page.',
          });
          this.menuService.reloadMenu();
        }
        return throwError(() => error);
      })
    );
  }
}
