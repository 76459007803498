import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { MenuChangeEvent } from './../api/menuchangeevent';
import { DataWithLoading, ListWithLoading } from 'src/app/shared/interfaces/common-store.interfaces';
import { MenuItem } from 'primeng/api';
import { isAuthorizedRoute, isAuthorizedRouteByKey } from 'src/app/authentication/guards/auth.navigation.guard';
import { NewCommonBehaviorSubject } from 'src/app/shared/models/newCommonBehaviorSubject.model';
import { NewHttpService } from 'src/app/shared/services/http2.service';
import { AdminMenuItem } from 'src/app/shared/interfaces/menu.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends NewCommonBehaviorSubject<MenuStore,never, MenuStoreSelectors, AllMenuStoreSelectors> {

  constructor(
    private httpService: NewHttpService,
  ) {
    super({
      menuItems: {
        data: [],
        loading: false
      }
    }, httpService);
  }

  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }

  reset() {
    this.resetSource.next(true);
  }

  getMenu(){
    return super.getDataWithLoading(
      'GetAdminMenu',
      {},
      ['menuItems'],
      {
        mapFunction: (res: any) => {
          return [{ items: res?.data }];
        }
      }
    ).pipe();
  }

  checkIsAuthorizedRoute(route: string): Observable<boolean>{
    return this.selector$(['menuItems', 'data']).pipe(
      map(data => {
        if(!data || !data?.length) return false
        const menuItemsData = data[0]?.items;
        const _isAuthorizedRoute = isAuthorizedRoute(menuItemsData, route);
        return _isAuthorizedRoute;
      })
    )
  }

  checkIsAuthorizedRouteByKey(key: string): Observable<boolean>{
    return this.selector$(['menuItems', 'data']).pipe(
      map(data => {
        if(!data || !data?.length) return false
        const menuItemsData = data[0]?.items;
        const _isAuthorizedRoute = isAuthorizedRouteByKey(menuItemsData, key);
        return _isAuthorizedRoute;
      })
    )
  }
  
  reloadMenu(){
    this.resetStoreToInitialState();
    this.getMenu().subscribe();
  }

}

export interface MenuStore {
  menuItems: ListWithLoading<AdminMenuItem>
}

type MenuStoreSelectors = keyof MenuStore;
type AllMenuStoreSelectors = keyof MenuStore | keyof DataWithLoading;
