export function _editNestedProperty(olObj: any, propertyPath: string[], value: any): any {
  const obj = olObj;
  const [propertyName, ...remainingPath] = propertyPath;

  if (!propertyName) {
    // Base case: we've reached the end of the property path, so return the updated object
    return value;
  }

  const nestedObj = obj[propertyName];

  if (!nestedObj) {
    // If the nested object doesn't exist, create it
    obj[propertyName] = {};
  }

  // Recursively update the nested property
  obj[propertyName] = _editNestedProperty(nestedObj, remainingPath, value);

  // Return the updated object
  return obj;
}


export function _getNestedValue(data: any, keys: string[]): any {
  return keys.reduce((value, key) => {
    if (value.hasOwnProperty(key)) {
      return value[key];
    } else {
      // Handle invalid key or missing nested property
      return null;
    }
  }, data);
}

export function updateNestedProperty(obj: any, path: string[], newState: any): any {
  const updateProperty = (nestedObj: any, key: string, index: number): any => {
    if (index === path.length - 1) {
      nestedObj[key] = newState;
    }
    return nestedObj[key];
  };
  return path.reduce(updateProperty, obj);
}


export function findAndRemove<T>(arr: T[], predicate: (item: T) => boolean): T | undefined {
  const index = arr.findIndex(predicate);
  if (index !== -1) {
      return arr.splice(index, 1)[0];
  }
  return undefined;
}
