import {
  BehaviorSubject,
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { updateNestedProperty } from '../utils/array.utils';
import {
  DataWithLoading,
  DialogState,
  LocalPathData_Data,
  LocalPathData_Pagination,
} from '../interfaces/common-store.interfaces';
import { _cleanObjectOfEmpty } from '../utils/http.utils';
import {
  NotNull,
  NotNullObs,
  KeyofAllProps,
} from '../types/custom-types.types';
import {
  defaultNewPostOptions,
  NewGetApiMethodsCallOptions,
  NewHttpService,
  NewPostApiMethodsCallOptions,
} from '../services/http2.service';
import { model } from '@angular/core';
export class NewCommonBehaviorSubject<
  StoreType extends ExpectedStore = ExpectedStore,
  Depth extends number | never = 4,
  StoreProps extends keyof StoreType = keyof StoreType,
  AllStoreProps = KeyofAllProps<StoreType, 4>
> {
  private store: BehaviorSubject<StoreType>;
  newHttp!: NewHttpService;
  private readonly _initialState: StoreType;
  constructor(initialState: StoreType, newHttp?: NewHttpService) {
    this.store = new BehaviorSubject<StoreType>(initialState);
    this._initialState = {...initialState};
    if (newHttp) this.newHttp = newHttp;
  }

  get value() {
    return this.store.value;
  }
  getValue() {
    return this.store.getValue();
  }

  get asObservable$() {
    return this.store.asObservable();
  }

  nestedSelector$(selector: AllStoreProps[]) {}

  next(value: StoreType) {
    this.store.next(value);
  }

  updateStore(newState: Partial<StoreType>) {
    this.store.next({
      ...this.value,
      ...newState,
    });
  }

  getDataFromStoreValue(path: AllStoreProps[] | StoreProps) {
    if (Array.isArray(path)) {
      return path.reduce((acc, curr) => {
        return acc?.[curr]!;
      }, this.value as any);
    }
    return this.value?.[path];
  }

  updateDataWithLoadingInStore(
    path: AllStoreProps[] | StoreProps,
    newState: any,
    loading: boolean = false
  ) {
    const data: DataWithLoading = {
      data: newState,
      loading: loading,
    };
    if (Array.isArray(path)) {
      this.updateNestedInStore(path, data);
    } else {
      this.updateStore({ [path]: data } as Partial<StoreType>);
    }
    // this.updateStore({
    //   ...newData
    // });
  }

  updateLoadingInStore(
    path: AllStoreProps[] | StoreProps,
    loading: boolean = false
  ) {
    if (Array.isArray(path)) {
      path = [...path, 'loading'] as AllStoreProps[];
    } else {
      path = [path, 'loading'] as AllStoreProps[];
    }
    this.updateNestedInStore(path, loading);
  }

  // update data in the store with switch if the path is first level or nested
  updateDataInStore(path: AllStoreProps[] | StoreProps, newState: any) {
    if (Array.isArray(path)) {
      this.updateNestedInStore(path, newState);
    } else {
      this.updateStore({ [path]: newState } as Partial<StoreType>);
    }
    // this.updateStore({
    //   ...newData
    // });
  }

  // update data in the store that in is nested property
  updateNestedInStore(path: AllStoreProps[] | StoreProps, newState: any) {
    const newData = updateNestedProperty(
      this.value,
      path as string[],
      newState
    );
    this.updateStore({
      ...newData,
    });
  }

  selector$<Type = any>(
    selector: AllStoreProps[] | StoreProps
  ): NotNullObs<Type> {
    if (Array.isArray(selector)) {
      return this.asObservable$.pipe(
        map((store) =>
          selector.reduce((acc, curr) => {
            return acc?.[curr]!;
          }, store as any)
        ),
        distinctUntilChanged()
      ) as NotNullObs<Type>;
    }
    return this.asObservable$.pipe(
      map((store) => store?.[selector]),
      distinctUntilChanged()
    ) as NotNullObs<Type>;
  }

  getDataWithLoading(
    endPoint: string,
    params: any,
    selector: AllStoreProps[],
    options?: NewGetApiMethodsCallOptions
  ) {
    this.updateLoadingInStore(selector, true);
    return this.newHttp.getData(endPoint, { ...params }, options).pipe(
      tap((response: any) => {
          if (options?.insidePropName)
            this.updateDataWithLoadingInStore(
              selector,
              response?.[options?.insidePropName]
            );
          else if (options?.mapFunction) {
            this.updateDataWithLoadingInStore(
              selector,
              options?.mapFunction(response)
            );
          } else {
            this.updateDataWithLoadingInStore(selector, response);
          }
        },
      ),
      finalize(() => {
        this.updateLoadingInStore(selector, false);
      })
    );
  }

  // handleAutocompleteMethod() {
  //   return this.autocompleteEvent$.pipe(
  //     filter(query => query.length > 2),
  //     debounceTime(300),
  //     switchMap((query:string) => this.autoCompleteApiMethod(query)),
  //     takeUntil(this.destroy$),
  //   )
  // }

  addItemToListWithLoading(
    path: AllStoreProps[] | StoreProps,
    itemData: any,
    order: 'first' | 'last' = 'last'
  ) {
    const listWithLoading = this.getDataFromStoreValue(path);
    console.log('listWithLoading:', listWithLoading);
    const list = { ...listWithLoading?.data };
    if (order == 'first') {
      list.unshift(itemData);
    } else {
      list.push(itemData);
    }
    // console.log('list:', list);
    this.updateDataWithLoadingInStore(path, list);
  }

  deleteLocalItemFromListWithLoading(
    path: AllStoreProps[] | StoreProps,
    primaryKeyName: string,
    value: any,
    listPath?: AllStoreProps[]
  ) {
    const listWithLoading = this.getDataFromStoreValue(
      listPath ? listPath : path
    );
    // console.log('listWithLoading:', listWithLoading);

    const list = (listPath ? listWithLoading : listWithLoading?.data)?.filter(
      (item: any) => item[primaryKeyName] != value
    );
    // console.log('list:', list);
    if (listPath) {
      this.updateNestedInStore(listPath!, list);
    } else this.updateDataWithLoadingInStore(path, list);
  }

  sendNowItemFromListWithLoading(
    endPoint: string,
    data: any,
    config: NewPostApiMethodsCallOptions = defaultNewPostOptions,
    selector: AllStoreProps[] | StoreProps
  ) {
    this.updateLoadingInStore(selector, true);

    return this.newHttp
      .postData(
        endPoint,
        {
          body: data,
        },
        config
      )
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.updateLoadingInStore(selector, false);
        },
        error: (error: any) => {
          this.updateLoadingInStore(selector, false);
        },
      });
  }

  updateLocalItemInListWithLoading(
    path: AllStoreProps[] | StoreProps,
    primaryKeyName: string,
    value: any,
    newData: any
  ) {
    const listWithLoading = this.getDataFromStoreValue(path);
    const list = listWithLoading?.data?.map((item: any) => {
      if (item[primaryKeyName] == value) {
        return { ...item, ...newData };
      }
      return item;
    });
    this.updateDataWithLoadingInStore(path, list);
  }

  changeDialogState<T = any>(
    selector: AllStoreProps[] | StoreProps,
    state: Partial<DialogState<T>>
  ) {
    const oldData: DialogState<T> = this.getDataFromStoreValue(selector);
    this.updateDataInStore(selector, {
      ...oldData,
      ...state,
    });
  }

  openDialog(selector: AllStoreProps[] | StoreProps, data?: any): void {
    if (data == undefined)
      return this.changeDialogState(selector, {
        visible: true,
        data: this.getDataFromStoreValue(
          this.mergePaths(selector, 'data' as AllStoreProps)
        ),
      });
    if (data == null || data)
      return this.changeDialogState(selector, {
        visible: true,
        data: data,
      });
  }

  closeDialog(selector: AllStoreProps[] | StoreProps) {
    return this.changeDialogState(selector, {
      visible: false,
      data: null,
    });
  }

  mergePaths(
    selector: AllStoreProps[] | StoreProps,
    forMerge: AllStoreProps[] | AllStoreProps
  ): AllStoreProps[] {
    if (Array.isArray(selector)) {
      if (Array.isArray(forMerge)) {
        return [...selector, ...forMerge] as AllStoreProps[];
      } else {
        return [...selector, forMerge] as AllStoreProps[];
      }
    } else {
      if (Array.isArray(forMerge)) {
        return [selector, ...forMerge] as AllStoreProps[];
      } else {
        return [
          selector as StoreProps,
          forMerge as AllStoreProps,
        ] as AllStoreProps[];
      }
    }
  }

  defaultDataValue(firstSelector: StoreProps): LocalPathData_Data {
    return this.getDataFromStoreValue([
      firstSelector,
      'data',
      'data',
    ] as AllStoreProps[]);
  }

  defaultDataMainPaginationValue(
    firstSelector: StoreProps
  ): LocalPathData_Pagination {
    const { pageSize, pageNumber, totalItems } = this.defaultDataValue(firstSelector);
    return { pageSize, pageNumber, totalItems };
  }

  defaultData$(firstSelector: StoreProps){
    return this.selector$([
      firstSelector,
      'data',
      'data',
    ] as AllStoreProps[])
  }

  resetStoreToInitialState(){
    this.store.next({...this._initialState});
  }
}

export interface ExpectedStore {
  [key: string]: any;
}
